import React from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button, LegacyForms, ColorPicker } from '@grafana/ui';

export class ColorMap {
  color = '';
  status = '';
}

export const ColorSetter: React.FC<StandardEditorProps<ColorMap[]>> = ({ item, value, onChange, context }) => {

  const addStatusDefinition = () => {
    const newStatusesArr = value;
    newStatusesArr.push({ color: '#ffffff', status: '' });

    onChange(newStatusesArr);
  };

  const removeStatusDefinition = (color: ColorMap, index: number) => {
    const newStatusesArr = value;
    newStatusesArr.splice(index, 1);

    onChange(newStatusesArr);
  };

  const onStatusChanged = (color: ColorMap, index: number) => {
    const newStatusesArr = value;
    newStatusesArr[index] = color;

    onChange(newStatusesArr);
  };

  return (
    <div>
      {value.map((color, index) => (
        <div key={index} className="gf-form">
          <span className="gf-form-label">
            <i className="fa fa-remove pointer" onClick={() => removeStatusDefinition(color, index)}></i>
          </span>
          &nbsp;
          <LegacyForms.FormField
            label="Status"
            labelWidth={200}
            inputWidth={200}
            type="text"
            value={color.status}
            onChange={(t: any) => onStatusChanged({ color: color.color, status: t.target.value }, index)}
          />
          &nbsp;
          <span className="gf-form-label">
            <i className="fa fa-arrow-right"></i>
          </span>
          &nbsp;
          <LegacyForms.FormField
            label="Color"
            labelWidth={200}
            inputWidth={200}
            type="text"
            value={color.color}
            onChange={(t: any) => onStatusChanged({ color: t.target.value, status: color.status }, index)}
          />
          &nbsp;
          <span className="gf-form-label">
            <ColorPicker
              color={color.color}
              onChange={(e) => onStatusChanged({ color: e, status: color.status }, index)}
            ></ColorPicker>
          </span>
        </div>
      ))}
      <br />
      <Button onClick={addStatusDefinition}>Add status</Button>
    </div>
  );
};
