import React, { useState, useEffect } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button } from '@grafana/ui';
import { Vector3 } from 'three';
import objects from './objects';

export const CameraPositionSetter: React.FC<StandardEditorProps<Vector3 | null>> = ({ item, value, onChange, context }) => {

  let [camPosition, setCamPosition] = useState(objects.ThreeScene !== null && objects.ThreeScene !== undefined ? objects.ThreeScene.getCameraPositionDetails() : null);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if(camPosition === null || camPosition === undefined){
		setCamPosition(() => objects.ThreeScene !== null && objects.ThreeScene !== undefined ? objects.ThreeScene.getCameraPositionDetails() : null);
	  }
    }, 1000);
    return () => clearInterval(interval);
  }, [camPosition]);
  
  
  const applyCameraPosition = () => {
	camPosition = objects.ThreeScene.getCameraPositionDetails();
	
	const newVal = new Vector3(camPosition.x, camPosition.y, camPosition.z);
	
	onChange(newVal);
  }
  
  const useStaticOptions = () => {	
	onChange(null);
  }

  return (
    <div className="section gf-form-group">
	<div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-300">Current Camera Postion Details</label>			
		</div>		
	  </div>
      <div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-100">X:</label>
			<input type="number" title="camPosition.x" className="gf-form-input width-100" disabled={true} value={camPosition !== null && camPosition !== undefined ? camPosition.x.toString() : ""} />
		</div>		
	  </div>
	  <div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-100">Y:</label>
			<input type="number" title="camPosition.y" className="gf-form-input width-100" disabled={true}  value={camPosition !== null && camPosition !== undefined ? camPosition.y.toString() : ""} />
		</div>		
	  </div>
	  <div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-100">Z:</label>
			<input type="number" title="camPosition.z" className="gf-form-input width-100" disabled={true} value={camPosition !== null && camPosition !== undefined ? camPosition.z.toString() : ""} />
		</div>		
	  </div>
      <br />	  
      <Button onClick={applyCameraPosition}>Set current view</Button>
	  <br />
	  <br />
	  <Button onClick={useStaticOptions}>Use static options</Button>
    </div>
  );
};
