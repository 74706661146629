import { LayoutMachine } from './types';
import { Field } from '@grafana/data';

export class OptionsHelper {
  static readWithVariables(text: string, props: any): string {
    const hasVar = text.indexOf('$') > -1 || text.indexOf('[[') > -1;
    if (!hasVar) {
      return text;
    }
    const varText = props.replaceVariables(text);
    return varText;
  };  
};

export class TimeHelper {
  static twoDigitPad(num: number) {
      return num < 10 ? "0" + num : num;
  }

  static addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes*60000);
  }

  static formatUnixTime(timeUnix: number, format: string, timezone: string): string {
    return this.formatDate(new Date(timeUnix), format, timezone);
  }

  static formatDate(date: Date, format: string, timezone: string): string {
    let monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    let dayOfWeekNames = [
      "Sunday", "Monday", "Tuesday",
      "Wednesday", "Thursday", "Friday", "Saturday"
    ];

    let offsetMinutes = 0;
    if (timezone && timezone !== null && timezone.trim().length > 0) {
      if (timezone.toLowerCase() === 'browser') {
        //get browser timezone offset
        //Expected output: browser timezone offset in minutes
        //accounts for DST
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
        offsetMinutes = date.getTimezoneOffset();
      }
      else if (timezone.toLowerCase() !== 'utc') {
        //get timezone offset by timezone name
        //?!?!?!?!?!?!?!?
      }
    }

    if (offsetMinutes !== 0) {
      date = this.addMinutes(date, offsetMinutes*-1);
    }

    if (!format || format === null || format.trim().length === 0) {
      return date.toLocaleString();
    }

    let result = format;
    let day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        miliseconds = date.getMilliseconds(),
        h = hour % 12,
        hh = this.twoDigitPad(h),
        HH = this.twoDigitPad(hour),
        mm = this.twoDigitPad(minute),
        ss = this.twoDigitPad(second),
        aaa = hour < 12 ? 'AM' : 'PM',
        EEEE = dayOfWeekNames[date.getDay()],
        EEE = EEEE.substring(0, 3),
        dd = this.twoDigitPad(day),
        M = month + 1,
        MM = this.twoDigitPad(M),
        MMMM = monthNames[month],
        MMM = MMMM.substring(0, 3),
        yyyy = year + "",
        yy = yyyy.substring(2, 2)
    ;
    
    result = result
      .replace('hh', hh.toString()).replace('h', h.toString())
      .replace('HH', HH.toString()).replace('H', hour.toString())
      .replace('mm', mm.toString()).replace('m', minute.toString())
      .replace('ss', ss.toString()).replace('s', second.toString())
      .replace('S', miliseconds.toString())
      .replace('dd', dd.toString()).replace('d', day.toString())
      
      .replace('EEEE', EEEE).replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa);
    if (result.indexOf('MMM') > -1) {
        result = result
          .replace('MMMM', MMMM)
          .replace('MMM', MMM);
    }
    else {
        result = result
          .replace('MM', MM.toString())
          .replace('M', M.toString());
    }
    return result;
  };  
};

export class MachineHelper {
  static machineIsForSetupInspection(machine: LayoutMachine, props: any): boolean {
    const setupInspectionMachines: string = OptionsHelper.readWithVariables(props.options.setupInspectionMachines, props);
    
    if (!machine || !setupInspectionMachines || !machine.showERPData)
      {return false;}

    let splitString = setupInspectionMachines.split(',');
    let found = false;
    for (let i = 0; i < splitString.length; i++) {
      let stringPart = splitString[i].trim();
      if (stringPart !== machine.sensorId) {continue;}

      found = true;
      break;
    }

    return found;
  };
};

export class GenericHelper {
  static getFieldValuesOrNull(fields: Field[], fieldName: string) {
    let fieldsFound = fields.filter(function (f) {
      return f.name === fieldName;
    });
    if (fieldsFound !== null && fieldsFound.length > 0)
    {
      return fieldsFound[0].values.toArray();
    }
    return null;
  }

  static isNumber(value?: string | number): boolean
  {
     return ((value != null) &&
             (value !== '') &&
             !isNaN(Number(value.toString())));
  }
};
