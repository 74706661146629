import React, { useState, useEffect } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button } from '@grafana/ui';
import { Vector3 } from 'three';
import objects from './objects';

export const ControlsTargetPositionSetter: React.FC<StandardEditorProps<Vector3 | null>> = ({ item, value, onChange, context }) => {

  let [controlsTargetPostion, setControlsTargetPostion] = useState(objects.ThreeScene !== null && objects.ThreeScene !== undefined ? objects.ThreeScene.getControlsTargetPositionDetails() : null);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if(controlsTargetPostion === null || controlsTargetPostion === undefined){
		setControlsTargetPostion(() => objects.ThreeScene !== null && objects.ThreeScene !== undefined ? objects.ThreeScene.getControlsTargetPositionDetails() : null);
	  }
    }, 1000);
    return () => clearInterval(interval);
  }, [controlsTargetPostion]);
  
  
  const applyCameraPosition = () => {
	controlsTargetPostion = objects.ThreeScene.getControlsTargetPositionDetails();
	
	const newVal = new Vector3(controlsTargetPostion.x, controlsTargetPostion.y, controlsTargetPostion.z);
	
	onChange(newVal);
  }
  
  const useStaticOptions = () => {	
	onChange(null);
  }

  return (
    <div className="section gf-form-group">
	<div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-300">Map Center Point Details</label>			
		</div>		
	  </div>
      <div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-100">X:</label>
			<input type="number" title="controlsTargetPostion.x" className="gf-form-input width-100" disabled={true} value={controlsTargetPostion !== null && controlsTargetPostion !== undefined ? controlsTargetPostion.x.toString() : ""} />
		</div>		
	  </div>
	  <div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-100">Y:</label>
			<input type="number" title="controlsTargetPostion.y" className="gf-form-input width-100" disabled={true}  value={controlsTargetPostion !== null && controlsTargetPostion !== undefined ? controlsTargetPostion.y.toString() : ""} />
		</div>		
	  </div>
	  <div className="gf-form">
		<div className="form-field">
			<label className="gf-form-label width-100">Z:</label>
			<input type="number" title="controlsTargetPostion.z" className="gf-form-input width-100" disabled={true} value={controlsTargetPostion !== null && controlsTargetPostion !== undefined ? controlsTargetPostion.z.toString() : ""} />
		</div>		
	  </div>
      <br />	  
      <Button onClick={applyCameraPosition}>Set current point</Button>
	  <br />
	  <br />
	  <Button onClick={useStaticOptions}>Use map center</Button>
    </div>
  );
};
