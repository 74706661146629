import { PanelPlugin } from '@grafana/data';
import { defaults, FactoryLayoutOptions } from './types';
import { FactoryLayoutPanel } from './FactoryLayoutPanel';
import { ColorSetter } from 'controls/ColorSetter';
import { MetricSetter } from 'controls/MetricSetter';
import { CameraPositionSetter } from './CameraPositionSetter';
import { ControlsTargetPositionSetter } from './ControlsTargetPositionSetter' 

export const plugin = new PanelPlugin<FactoryLayoutOptions>(FactoryLayoutPanel).setPanelOptions(builder => {
    return builder
        .addNumberInput({
            name: 'Floor height',
            path: 'floorHeight',
            category: ['Map'],
            defaultValue: defaults.floorHeight
        })
        .addNumberInput({
            name: 'Floor offset',
            path: 'floorOffset',
            category: ['Map'],
            defaultValue: defaults.floorOffset
        })
        .addColorPicker({
            name: 'Background color',
            path: 'backgroundColor',
            category: ['Map'],
            defaultValue: defaults.backgroundColor
        })
        .addColorPicker({
            name: 'Background color (Light theme)',
            path: 'backgroundColorLight',
            category: ['Map'],
            defaultValue: defaults.backgroundColorLight
        })
        .addBooleanSwitch({
            name: 'Grid',
            path: 'grid',
            category: ['Map'],
            defaultValue: defaults.grid
        })
        .addColorPicker({
            name: 'Grid color',
            path: 'gridColor',
            category: ['Map'],
            defaultValue: defaults.gridColor + ''
        })
        .addNumberInput({
            name: 'Font size',
            path: 'fontSize',
            category: ['Machines'],
            defaultValue: defaults.fontSize
        })
        .addNumberInput({
            name: 'Alarm icon size',
            path: 'alarmIconSize',
            category: ['Machines'],
            defaultValue: defaults.alarmIconSize
        })
        .addNumberInput({
            name: 'Wrench icon size',
            path: 'wrenchIconSize',
            category: ['Machines'],
            defaultValue: defaults.wrenchIconSize
        })
        .addNumberInput({
            name: 'Restricted Access icon size',
            path: 'restrictedAccessIconSize',
            category: ['Machines'],
            defaultValue: defaults.restrictedAccessIconSize
        })
        .addTextInput({
            name: 'Dashboard URL part 1',
            path: 'dashboardURLpart1',
            category: ['Machines'],
            defaultValue: defaults.dashboardURLpart1
        })
        .addTextInput({
            name: 'Dashboard URL part 2',
            path: 'dashboardURLpart2',
            category: ['Machines'],
            defaultValue: defaults.dashboardURLpart2
        })
        .addBooleanSwitch({
            name: 'Show status base',
            path: 'showBase',
            category: ['Machines'],
            defaultValue: defaults.showBase
        })
        .addNumberInput({
            name: 'Base size',
            path: 'baseSize',
            showIf: o => o.showBase,
            category: ['Machines'],
            defaultValue: defaults.baseSize
        })
        .addBooleanSwitch({
            name: 'Show status lamp',
            path: 'showLamp',
            category: ['Machines'],
            defaultValue: defaults.showLamp
        })
        .addNumberInput({
            name: 'Lamp size',
            path: 'lampSize',
            showIf: o => o.showLamp,
            category: ['Machines'],
            defaultValue: defaults.lampSize
        })
        .addBooleanSwitch({
            name: 'Emissive status light',
            path: 'emissiveStatusLight',
            category: ['Machines'],
            defaultValue: defaults.emissiveStatusLight
        })
        .addBooleanSwitch({
            name: 'Show all maintenance',
            path: 'showAllMaintenance',
            category: ['Machines'],
            defaultValue: defaults.showAllMaintenance
        })
        .addNumberInput({
            name: 'Machine opacity',
            path: 'machineOpacity',
            category: ['Machines'],
            defaultValue: defaults.machineOpacity
        })
        .addColorPicker({
            name: 'Machine color',
            path: 'machineColor',
            category: ['Machines'],
            defaultValue: defaults.machineColor
        })
        .addTextInput({
            name: 'Not Online Status Filter (CSV)',
            path: 'notOnlineStatusFilter',
            category: ['Machines'],
            defaultValue: defaults.notOnlineStatusFilter
        })
        .addColorPicker({
            name: 'Restricted Access Color',
            path: 'restrictedAccessColor',
            category: ['Machines'],
            defaultValue: defaults.restrictedAccessColor
        })
        .addColorPicker({
            name: 'Started maintenance color',
            path: 'startedMaintenanceColor',
            category: ['Machines'],
            defaultValue: defaults.startedMaintenanceColor
        })
        .addColorPicker({
            name: 'Planned maintenance color',
            path: 'plannedMaintenanceColor',
            category: ['Machines'],
            defaultValue: defaults.plannedMaintenanceColor
        })
        .addNumberInput({
            name: 'Maintenance started status',
            path: 'maintenanceStartedStatus',
            category: ['Machines'],
            defaultValue: defaults.maintenanceStartedStatus
        })
        .addNumberInput({
            name: 'Maintenance history status',
            path: 'maintenanceHistoryStatus',
            category: ['Machines'],
            defaultValue: defaults.maintenanceHistoryStatus
        })
        .addBooleanSwitch({
            name: 'Do not load 3D models',
            path: 'disable3dModels',
            category: ['Machines'],
            defaultValue: defaults.disable3dModels
        })
        .addBooleanSwitch({
            name: 'Use HTML labels',
            path: 'useHtmlLabels',
            category: ['Machines'],
            defaultValue: defaults.useHtmlLabels
        })
        //camera
        .addNumberInput({
            name: 'Camera position x',
            path: 'cameraPosition.x',
            category: ['Camera'],
            defaultValue: defaults.cameraPosition.x
        })
        .addNumberInput({
            name: 'Camera position y',
            path: 'cameraPosition.y',
            category: ['Camera'],
            defaultValue: defaults.cameraPosition.y
        })
        .addNumberInput({
            name: 'Camera position z',
            path: 'cameraPosition.z',
            category: ['Camera'],
            defaultValue: defaults.cameraPosition.z
        }).addBooleanSwitch({
            name: 'Use 2D mode',
            path: 'use2DMode',
            category: ['Camera'],
            defaultValue: defaults.use2DMode
        }).addSelect({
            name: 'Default floor',
            path: 'defaultFloor',
            category:['Camera'],
            defaultValue:defaults.defaultFloor,
            settings: {
                options: [
                  { value: 0, label: 'Floor 0' },
                  { value: 1, label: 'Floor 1' }, 
                  { value: 2, label: 'Floor 2' }, 
                ],
              }            
        })
		.addCustomEditor({
            name: 'Actual Camera position',
            path: 'actualCameraPosition',
            editor: CameraPositionSetter,
            id: '3',
            category: ['Camera'],
			defaultValue: defaults.actualCameraPosition
        })
		.addCustomEditor({
            name: 'Actual Controls Target Position',
            path: 'actualControlsTargetPosition',
            editor: ControlsTargetPositionSetter,
            id: '4',
            category: ['Camera'],
			defaultValue: defaults.actualControlsTargetPosition
        })
        .addCustomEditor({
            name: 'Status Color Mapping',
            path: 'statuses',
            editor: ColorSetter,
            id: '1',
            category: ['Status Color Mapping']
        })
        .addCustomEditor({
            name: '',
            path: 'gaugeTypes',
            editor: MetricSetter,
            id: '2',
            category: ['Metric Types']
        })

        //labels
        .addTextInput({
            category: ['Labels'],
            path: 'workOrderTxt',
            name: 'Work order text',
            defaultValue: 'Work Order'
        })
        .addNumberInput({
            name: 'Work order column width',
            path: 'workOrderWidth',
            category: ['Labels'],
            defaultValue: defaults.workOrderWidth
        })
        .addTextInput({
            category: ['Labels'],
            path: 'partTxt',
            name: 'Part text',
            defaultValue: 'Part'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'operationTxt',
            name: 'Operation text',
            defaultValue: 'Operation'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'operatorTxt',
            name: 'Operator text',
            defaultValue: 'Operator'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'startDateTxt',
            name: 'Start date text',
            defaultValue: 'Started'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'endDateTxt',
            name: 'End date text',
            defaultValue: 'Ended'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'progressPartsTxt',
            name: 'Progress parts text',
            defaultValue: 'Progress parts'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'scrapQuantityTxt',
            name: 'Scrap quantity text',
            defaultValue: 'Scrap'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'progressTimeTxt',
            name: 'Progress time text',
            defaultValue: 'Progress time'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'planEndTxt',
            name: 'Plan end text',
            defaultValue: 'Plan end'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'departmentTxt',
            name: 'Department text',
            defaultValue: 'Department'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'teamTxt',
            name: 'Team text',
            defaultValue: 'Team'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'allTxt',
            name: 'All text',
            defaultValue: 'All'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'unassignedTxt',
            name: 'Unassigned text',
            defaultValue: 'Unassigned'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'floorTxt',
            name: 'Floor text',
            defaultValue: 'Floor'
        })
        //Setup inspection
        .addTextInput({
            name: 'Setup inspection machines (csv)',
            path: 'setupInspectionMachines',
            category: ['Setup inspection'],
            defaultValue: defaults.setupInspectionMachines
        })
        .addTextInput({
            name: 'Setup inspection dashboard URL',
            path: 'setupInspectionDashboard',
            category: ['Setup inspection'],
            defaultValue: defaults.setupInspectionDashboard
        })

        .addTextInput({
            category: ['Setup inspection'],
            path: 'siStatusTxt',
            name: 'Status text',
            defaultValue: 'Status'
        })
        .addNumberInput({
            name: 'Status column width',
            path: 'siStatusWidth',
            category: ['Setup inspection'],
            defaultValue: defaults.siStatusWidth
        })

        .addTextInput({
            category: ['Setup inspection'],
            path: 'siOrderTxt',
            name: 'Order-position-op text',
            defaultValue: 'OrderPosOp'
        })
        .addNumberInput({
            name: 'Order-position-op column width',
            path: 'siOrderWidth',
            category: ['Setup inspection'],
            defaultValue: defaults.siOrderWidth
        })

        .addTextInput({
            category: ['Setup inspection'],
            path: 'siMachineTxt',
            name: 'Machine text',
            defaultValue: 'Machine'
        })
        .addNumberInput({
            name: 'Machine column width',
            path: 'siMachineWidth',
            category: ['Setup inspection'],
            defaultValue: defaults.siMachineWidth
        })

        .addTextInput({
            category: ['Setup inspection'],
            path: 'siReadytTxt',
            name: 'Ready text',
            defaultValue: 'Ready'
        })
        .addTextInput({
            category: ['Setup inspection'],
            path: 'siCompletedTxt',
            name: 'Completed text',
            defaultValue: 'Completed'
        })
        .addTextInput({
            category: ['Setup inspection'],
            path: 'siItemNoTxt',
            name: 'Item Number text',
            defaultValue: 'Item N'
        })
        .addTextInput({
            category: ['Setup inspection'],
            path: 'siDrawingTxt',
            name: 'Drawing text',
            defaultValue: 'Drawing'
        })
})
