import React, { Component } from 'react';
import { OrderDetailsPanelProps, LayoutMachine, MMSData } from 'types';
import Moment from 'react-moment';
import { MachineHelper } from './common';

class OrderDetailsPanel extends Component<OrderDetailsPanelProps> {
    constructor(props: Readonly<OrderDetailsPanelProps>) {
        super(props);
    }

    state: any = {
        show: true,
    };

    UNSAFE_componentWillReceiveProps(props: Readonly<OrderDetailsPanelProps>) {
        this.setState({ show: props.show, machine: props.machine });
    }

    render() {
        if (!this.state.machine) {
            return null;
        }

        if (MachineHelper.machineIsForSetupInspection(this.state.machine.userData, this.props)) {
            return (
                <div className="fl-order-panel">
                    {this.createSetupInspection()}
                </div>
            );
        }

        const mmsDetails = this.createMMSDetails();
        const orderDetails = this.createOrderDetails();

        if ((mmsDetails && mmsDetails !== null) || (orderDetails && orderDetails !== null)) {
            return (
                <div className="fl-order-panel">
                    {mmsDetails}
                    {orderDetails}
                </div>
            );
        }
        else  {
            return null;
        }
    }



    createMMSDetails(): React.ReactNode {
        if (!this.state.machine.userData.mms) {
            return null;
        }

        const mms: MMSData = this.state.machine.userData.mms as MMSData;
        let shutdownCheck: JSX.Element;
        if (mms.shutdownRequired) {
            shutdownCheck = <i className="fa fa-check-circle" aria-hidden="true" style={{ color: '#ff7f00' }}></i>;
        } else {
            shutdownCheck = <i className="fa fa-times" aria-hidden="true" style={{ color: '#878787' }}></i>;
        }

        const className = `fl-mms-table ${this.state.show ? 'fl-fade-in' : 'fl-fade-out'}`;
        return (
            <table className={className}>
                <thead>
                    <tr>
                        <td style={{ width: '80px' }} className={'fl-wo'}>
                            Shutdown
                        </td>
                        <td style={{ width: '65px' }}>Number</td>
                        <td>Short Description</td>
                        <td colSpan={2}>Description</td>
                        <td style={{ width: '85px' }}>{this.props.options.startDateTxt}</td>
                        <td style={{ width: '85px' }}>{this.props.options.endDateTxt}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={'fl-wo'} style={{ textAlign: 'center' }}>
                            {shutdownCheck}
                        </td>
                        <td>{mms.number}</td>
                        <td>{mms.shortDescription}</td>
                        <td colSpan={2}>{mms.description}</td>
                        <td>{this.parseDate(mms.startDate)}</td>
                        <td>{this.parseDate(mms.endDate)}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    createOrderDetails(): React.ReactNode {
        const contexts = (this.state.machine.userData as LayoutMachine).contexts;
        if (!contexts || contexts.length === 0) {
            return null;
        }
        const children: React.ReactNode[] = [];

    let useGanttTotalTime = contexts.every(c => c.totalGanttTime !== -1);
    
    contexts.forEach(c => {
      let scrapNode: React.ReactNode = <span style={{ color: '#696969' }}>{c.scrapQty}</span>;
      if (c.scrapQty > 0) {
        scrapNode = <span style={{ color: '#FF4500' }}>{c.scrapQty}</span>;
      }

      let totalTime: number;

      if(useGanttTotalTime){
        totalTime = (60 * Math.floor(c.totalGanttTime)) + Math.round(60*(c.totalGanttTime%1)); // c.totalGanttTime is in hours i.e. 5.98
      }else{
        totalTime = (c.orderedQty * c.timePerItem) + c.setupTime;
      }

      let timeProgressNode: React.ReactNode = (
        <td>
          <span>
            {this.formatMinutes(c.elapsedTime)}/{this.formatMinutes(totalTime)}
          </span>
        </td>
      );

            if (c.elapsedTime > totalTime) {
                timeProgressNode = (
                    <td>
                        <span style={{ color: '#FF4500' }}>
                            {this.formatMinutes(c.elapsedTime)}/{this.formatMinutes(totalTime)}
                        </span>
                    </td>
                );
            }

            children.push(
                <tr>
                    <td className={'fl-wo'}>
                        {c.wo} - {c.pos}
                    </td>
                    <td colSpan={2}>{c.posName}</td>
                    <td>
                        {c.op} - {c.opName}
                    </td>
                    <td>{c.operator}</td>
                    <td>{this.parseDate(c.from)}</td>
                    <td>{this.parseDate(c.to)}</td>
                    <td>
                        {c.goodQty} / {c.orderedQty} - {c.shiftGoodQty}
                    </td>
                    <td>{scrapNode}</td>
                    <td>{timeProgressNode}</td>
                    <td>{this.parseDate(c.opPlanStop, 'DD/MM')}</td>
                </tr>
            );
        });

        const className = `${this.state.show ? 'fl-fade-in' : 'fl-fade-out'}`;
        return (
            <table className={className}>
                <thead>
                    <tr>
                        <td style={{ width: this.props.options.workOrderWidth }} className={'fl-wo'}>
                            {this.props.options.workOrderTxt}
                        </td>
                        <td colSpan={2}>{this.props.options.partTxt}</td>
                        <td>{this.props.options.operationTxt}</td>
                        <td style={{ width: '65px' }}>{this.props.options.operatorTxt}</td>
                        <td style={{ width: '85px' }}>{this.props.options.startDateTxt}</td>
                        <td style={{ width: '85px' }}>{this.props.options.endDateTxt}</td>
                        <td style={{ width: '120px' }}>{this.props.options.progressPartsTxt}</td>
                        <td style={{ width: '45px' }}>{this.props.options.scrapQuantityTxt}</td>
                        <td style={{ width: '100px' }}>{this.props.options.progressTimeTxt}</td>
                        <td style={{ width: '60px' }}>{this.props.options.planEndTxt}</td>
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        );
    }

    createSetupInspection(): React.ReactNode {
        if (!this.props.setupInspections || this.props.setupInspections.length === 0) {
            return null;
        }
        const children: React.ReactNode[] = [];

        this.props.setupInspections.forEach(si => {
            children.push(
                <tr>
                    <td dangerouslySetInnerHTML={{ __html: si.Status }} />
                    <td>{si.OrderPositionOperation}</td>
                    <td>{si.Machine}</td>
                    <td>{this.parseDate(si.Ready, 'DD/MM/YYYY HH:mm', '-')}</td>
                    <td>{this.parseDate(si.Completed, 'DD/MM/YYYY HH:mm', '-')}</td>
                    <td>{si.ItemNo}</td>
                    <td>{si.Drawing}</td>
                </tr>
            );
        });

        const className = `${this.state.show ? 'fl-fade-in' : 'fl-fade-out'}`;
        return (
            <table className={className}>
                <thead>
                    <tr>
                        <td style={{ width: this.props.options.siStatusWidth }} >{this.props.options.siStatusTxt}</td>
                        <td style={{ width: this.props.options.siOrderWidth }}>{this.props.options.siOrderTxt}</td>
                        <td style={{ width: this.props.options.siMachineWidth }}>{this.props.options.siMachineTxt}</td>
                        <td >{this.props.options.siReadytTxt}</td>
                        <td >{this.props.options.siCompletedTxt}</td>
                        <td >{this.props.options.siItemNoTxt}</td>
                        <td >{this.props.options.siDrawingTxt}</td>
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        );
    }

    formatMinutes(min: number): React.ReactNode {
        const hours = Math.floor(min / 60);
        const minutes = Math.round(min % 60);
        return this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    }

    zeroPad(num: number, places: number) {
        let s = num + '';
        while (s.length < places) {
            s = '0' + s;
        }
        return s;
    }

    parseDate(date: string, format?: string, emptyText = "IN PROGRESS"): any {
        if (!date) {
            return <span className={'fl-blink'}>{emptyText}</span>;
        }

        if (!format) {
            format = 'DD/MM HH:mm';
        }

        return <Moment date={date} format={format} />;
    }
}
export default OrderDetailsPanel;
