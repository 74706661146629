import React, { Component } from 'react';
import { ColorMode, MetricProps, ValueMode, StatusColorDefinition } from 'types';

class Metric extends Component<MetricProps> {
  constructor(props: Readonly<MetricProps>) {
    super(props);
    const ang = this.calcAngle(props);
    const color = this.calcColor(props);
    this.state = {
      metric: props.metric,
      angle: 0,
      color: color,
    };
    setTimeout(() => {
      this.setState({
        metric: props.metric,
        angle: ang,
        color: color,
      });
    }, 150);
  }

  state: any = {
    angle: 0,
    value: 0,
  };

  UNSAFE_componentWillReceiveProps(props: Readonly<MetricProps>) {
    const ang = this.calcAngle(props);
    const color = this.calcColor(props);
    this.setState({
      metric: props.metric,
      angle: ang,
      color: color,
    });
  }

  calcAngle(props: Readonly<MetricProps>) {
    const value = Number(props.metric.value);
    if (value <= props.metric.definition.min) {
      return 0;
    }
    if (value >= props.metric.definition.max) {
      return 180;
    }
    const range = props.metric.definition.max - props.metric.definition.min;
    const correctedStartValue = value - props.metric.definition.min;
    const percent = correctedStartValue / range;

    return 180 * percent;
  }

  calcColor(props: Readonly<MetricProps>) {
    const valueNr = Number(props.metric.value);
    const valueStr = props.metric.value.toString().trim();

    if (props.metric.definition.applyStatusColorMap) {
      let statusColor: StatusColorDefinition | undefined = this.props.options.statuses.find(s => s.status.trim().toUpperCase() === valueStr.toUpperCase());
      if (statusColor !== undefined) {
        return statusColor.color;
      }
    }

    for (let t of props.metric.definition.thresholds.sort((a, b) => a.value - b.value)) {
      if ((!isNaN(valueNr) && valueNr >= t.value)
          || (isNaN(valueNr) && valueStr === t.value.toString())) {
        return t.color;
      }
    }
    
    return props.metric.definition.color;
  }

  render() {
    const color = this.state.color;
    const colorMode = this.state.metric.definition.colorMode;

    const value = this.state.metric.value;
    const unit = this.state.metric.definition.unit;
    const valueMode = this.state.metric.definition.valueMode;
    
    const timeStr: string = this.state.metric.timeStr;
  
    let valueHtml = (<></>);
    let gaugeHtml = (<></>);
    let dataClassName = 'fl-metric-data noGauge';
    if (valueMode === ValueMode.Gauge) {
      dataClassName = 'fl-metric-data';
      const gaugeColor = colorMode === ColorMode.Gauge ? color : this.state.metric.definition.defaultGaugeColor;
      gaugeHtml = (<>
        <div className="fl-gauge-a"></div>
        <div className="fl-gauge-b"></div>
        <div className="fl-gauge-c" style={{ transform: 'rotate(' + this.state.angle + 'deg)', backgroundColor: gaugeColor }}></div>
      </>);
      valueHtml = (<>
        <span className="fl-metric-value">{value}<span className="fl-metric-unit">{unit}</span></span>        
      </>)
    }
    else if (valueMode === ValueMode.Time) {
      valueHtml = (<span className="fl-metric-time">{timeStr}</span>);
    }
    else if (valueMode === ValueMode.TimeAndValue) {
      valueHtml = (<>
        <span className="fl-metric-value">{value}<span className="fl-metric-unit">{unit}</span></span>
        
        <br />
        <span className="fl-metric-time">{timeStr}</span>
      </>);
    }
    else if (valueMode === ValueMode.Value) {
      valueHtml = (<>
        <span className="fl-metric-value">{value}<span className="fl-metric-unit">{unit}</span></span>        
      </>)
    }

    const valueColor = colorMode === ColorMode.Value ? color : this.state.metric.definition.defaultValueColor;
    const titleColor = colorMode === ColorMode.Title ? color : this.state.metric.definition.defaultGaugeColor;
    return (
      <div className="fl-metric-container">
        <div className="fl-metric-title" style={{ color: titleColor }}>
          {this.state.metric.definition.displayName}
        </div>
        {gaugeHtml}
        <div className={dataClassName} style={{ color: valueColor }}>
          {valueHtml}
        </div>
      </div>
    );
  }
}
export default Metric;
