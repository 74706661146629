//MESH renderOrder
//map = 0
//machine = 1
//machineImages = 2
//selection box = 3
//machine number = 4
//status text = 5

import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { SceneProps, LayoutMachine, MMSData, MeshGeometry, PositionOnMachine } from 'types';
import GLTFLoader from 'three-gltf-loader';
import DRACOLoader from "three-dracoloader";
import { Scene, Vector3 } from 'three';
import TWEEN from '@tweenjs/tween.js';
import MetricPanel from 'MetricPanel';
import OrderDetailsPanel from 'OrderDetailsPanel';
import screenfull from 'screenfull';
import { getDataSourceSrv, config } from '@grafana/runtime';
import axios from 'axios';
import { MachineHelper } from './common';
import objects from 'objects';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'


class ThreeScene extends Component<SceneProps> {
    //THREE core objects
    scene!: THREE.Scene;
    mount: any;
    camera!: THREE.PerspectiveCamera;
    renderer!: THREE.WebGLRenderer;
    labelRenderer!: CSS2DRenderer;
    cube = new THREE.Mesh();
    frameId: any;
    controls!: OrbitControls;
    clock!: THREE.Clock;
    state: any = {
        showPanelLeft: false,
        showPanelBottom: false,
    };
    dracoLoader: DRACOLoader = new DRACOLoader();

    //floors objects
    currentFloor = 0;
    floors: any = {};

    //machine objects
    machines: THREE.Mesh[] = []; //Machine boxes
    machineMeshes: any = {}; //Same as MAchine boxes, added for easy removal on GLB loaded
    machineImages: any = {}; //picture of the machine, shown on one side of the machine box
    machineBases: any = {}; //Machine base which shows the status below the machine box
    glbMachineStatusBases: any = {}; //GLB machine base which shows the status below the machine model
    machineLamps: any = {}; //Lamp showing the status on top of the machine box
    machineLampBases: any = {}; //Lamp base
    machineNumberSprites: any = {};
    machineAnimations: any = {};
    machineMetricSprites: any = {};

    //default machine image
    defaultMachineImage: THREE.Texture | any;

    //Alarm icon, rotating on top of the machine
    alarmObject: any = null;
    alarmsDic: any = {};

    //Wrench icon, rotating on top of the machine
    wrenchObject: any = null;
    wrenchDic: any = {};

    //Restricted access icon, rotating on top of the machine - for demo users, indicates restricted machines
    restrictedAccessObject: any = null;
    restrictedAccessDic: any = {};

    //selecting machine
    mouse = new THREE.Vector2();
    mouseHasMoved = false;
    INTERSECTED: any;
    selectionMaterial = new THREE.MeshBasicMaterial({
        color: '#007ED6',
        transparent: true,
        opacity: 0.4,
        side: THREE.DoubleSide,
        depthWrite: false,
    });
    selectionMesh: any;
    selectionStatusText: any;

    proxyUrl: string | undefined;

    //touch
    touchTimer: any;
    touchDuration = 1000;

    constructor(props: Readonly<SceneProps>) {
        super(props);
        this.state = {
            showMaintenance: props.showMaintenance,
        };
        objects['ThreeScene'] = this;
    }

    getCameraPositionDetails() {
        let camPos = this.camera.position;
        return {
            x: camPos.x,
            y: camPos.y,
            z: camPos.z
        };
    }

    getControlsTargetPositionDetails() {
        let controlTargetPos = this.controls.target;
        return {
            x: controlTargetPos.x,
            y: controlTargetPos.y,
            z: controlTargetPos.z
        };
    }

    render() {
        let classNameLeft = 'fl-slider-left ';
        if (!this.state.showPanelLeft) {
            classNameLeft += 'fl-close'
        }
        else {
            classNameLeft += this.INTERSECTED.userData.metric.length <= 6 ? 'fl-slider-left-single' : 'fl-slider-left-double';
        }

        let classNameBottom = 'fl-slider-bottom';
        if (this.state.showPanelBottom === false) {
            classNameBottom += ' fl-close'
        }
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <div
                    style={{ width: '100%', height: '100%' }}
                    ref={mount => {
                        this.mount = mount;
                    }}
                />
                <div className={classNameLeft}>
                    <MetricPanel show={this.state.showPanelLeft} machine={this.INTERSECTED} options={this.props.options} />
                </div>
                <div className={classNameBottom}>
                    <OrderDetailsPanel show={this.state.showPanelBottom}
                        setupInspections={this.props.setupInspections}
                        machine={this.INTERSECTED}
                        options={this.props.options} />
                </div>
            </div>
        );
    }

    setObjectsBehaviour() {
        this.machines.forEach(machine => {
            //rotate alarm indicators
            if (this.alarmsDic[machine.userData.sensorId]) {
                this.alarmsDic[machine.userData.sensorId].rotation.y -= 0.05;
            }
            //rotate planned maintenance indicators
            if (this.wrenchDic[machine.userData.sensorId]) {
                this.wrenchDic[machine.userData.sensorId].rotation.y -= 0.05;
            }
            //restrictedAccesss always look at camera
            if (this.restrictedAccessDic[machine.userData.sensorId]) {
                this.restrictedAccessDic[machine.userData.sensorId].lookAt(this.camera.position);
            }
        });
    }

    loadAlarmObject() {
        const loader = new GLTFLoader();
        loader.load(
            '/public/img/factory-layout/obj/att.gltf',
            gltf => {
                gltf.scene.scale.set(this.props.options.alarmIconSize, this.props.options.alarmIconSize, this.props.options.alarmIconSize);
                gltf.scene.children.forEach(element => {
                    const material = (element as THREE.Mesh).material as THREE.MeshBasicMaterial;
                    material.color.setHex(0xff002a);
                });
                this.alarmObject = gltf.scene;
            },
            undefined,
            error => console.log(error)
        );
    }

    loadWrenchObject() {
        const loader = new GLTFLoader();
        loader.load(
            '/public/img/factory-layout/obj/wrench.gltf',
            gltf => {
                gltf.scene.scale.set(this.props.options.wrenchIconSize, this.props.options.wrenchIconSize, this.props.options.wrenchIconSize);
                this.wrenchObject = gltf.scene.clone();
                (this.wrenchObject as THREE.Scene).children.forEach(element => {
                    const material = (element as THREE.Mesh).material as THREE.MeshBasicMaterial;
                    material.color.setHex(0xff7f00);
                });
            },
            undefined,
            error => console.log(error)
        );
    }

    loadRestrictedAccessObject() {
        const loader = new GLTFLoader();
        loader.load(
            '/public/img/factory-layout/obj/restrictedAccess.gltf',
            gltf => {
                gltf.scene.scale.set(this.props.options.restrictedAccessIconSize, this.props.options.restrictedAccessIconSize, this.props.options.restrictedAccessIconSize);
                this.restrictedAccessObject = gltf.scene.clone();
                (this.restrictedAccessObject as THREE.Scene).children.forEach(element => {
                    const material = (element as THREE.Mesh).material as THREE.MeshBasicMaterial;
                    material.color.setHex(0xedc06b);
                });
            },
            undefined,
            error => console.log(error)
        );
    }

    loadMachineImage(machineMesh: THREE.Mesh) {
        const loader = new THREE.TextureLoader();
        loader.load(
            '/public/img/factory-layout/machines_transparent/' + machineMesh.userData.sensorId + '.png',
            texture => {
                this.applyMachineImage(machineMesh, texture);
            },
            undefined,
            err => {
                console.log(err);
                //apply default texture
                if (this.defaultMachineImage) {
                    this.applyMachineImage(machineMesh, this.defaultMachineImage);
                } else {
                    this.loadDefaultMachineImage(machineMesh);
                }
            }
        );
    }

    loadDefaultMachineImage(machineMesh: THREE.Mesh) {
        const loader = new THREE.TextureLoader();
        loader.load(
            '/public/img/factory-layout/machines_transparent/machine_default.png',
            texture => {
                this.defaultMachineImage = texture;
                this.applyMachineImage(machineMesh, texture);
            },
            undefined,
            err => {
                console.log(err);
            }
        );
    }

    applyMachineImage(machineMesh: THREE.Mesh, texture: THREE.Texture) {
        texture.needsUpdate = true;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;
        let boxWidth = machineMesh.userData.width;
        if (machineMesh.userData.face === 'east' || machineMesh.userData.face === 'west') {
            boxWidth = machineMesh.userData.depth;
        }
        const boxHeight = machineMesh.userData.height > 1 ? machineMesh.userData.height : machineMesh.userData.depth;
        const repeatX = (boxWidth * texture.image.height) / (boxHeight * texture.image.width);
        if (repeatX < 1) {
            const repeatY = (boxHeight * texture.image.width) / (boxWidth * texture.image.height);
            texture.repeat.set(1, repeatY);
            texture.offset.y = ((repeatY - 1) / 2) * -1;
        } else {
            texture.repeat.set(repeatX, 1);
            texture.offset.x = ((repeatX - 1) / 2) * -1;
        }
        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.machineImages[machineMesh.userData.sensorId].material = new THREE.MeshBasicMaterial({
            map: texture,
            color: 0xffffff,
            transparent: true,
        });
        this.updateMachineStatus(machineMesh);
    }

    onKeyPress(e: any) {
        if (e.keyCode !== 32) {
            //SPACE
            return;
        }
        this.changeFloor(undefined);
    }

    changeFloor(targetFloor: number | undefined) {
        let newFloor = 0;
        if (targetFloor !== undefined) {
            newFloor = targetFloor;
        }
        else {
            if ((this.currentFloor + 1) < this.props.layoutConfiguration.floors.length) {
                newFloor = this.currentFloor + 1;
            }
        }

        if (this.props.options.use2DMode) {
            this.floors[this.currentFloor].visible = false;
            this.floors[newFloor].visible = true;
            this.currentFloor = newFloor;
            this.updateMachines(this.props);
        } else {
            const startPosition = new Vector3(0, 10 + this.props.options.floorHeight * this.currentFloor, 0);
            const endPosition = new Vector3(0, 10 + this.props.options.floorHeight * newFloor, 0);
            const tween = new TWEEN.Tween(startPosition).to(endPosition, 400);
            tween.onUpdate(() => {
                this.controls.target.setY(startPosition.y);
            });
            tween.start();
            this.currentFloor = newFloor;
        }
    }

    onMouseUp(e: any) {
        if (this.INTERSECTED && this.INTERSECTED.clicked) {
            this.openDashbord(this.INTERSECTED.userData)
        }
        this.logCameraPosition();
    }

    openDashbord(userData: LayoutMachine) {
        if (MachineHelper.machineIsForSetupInspection(userData, this.props)) {
            window.open(this.props.options.setupInspectionDashboard);
        } else if (!this.INTERSECTED.userData.static && this.INTERSECTED.userData.URL.length > 0) {
            window.open(userData.URL);
        }
    }

    onMouseDown(e: any) {
        if (this.INTERSECTED) {
            this.INTERSECTED.clicked = true;
        }
    }

    updateOnMouseMove() {
        if (!this.mouseHasMoved) {
            return;
        }
        const vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 1);
        vector.unproject(this.camera);
        const ray = new THREE.Raycaster(this.camera.position, vector.sub(this.camera.position).normalize());
        let intersects = ray.intersectObjects(this.machines);
        if (intersects.length > 0) {
            this.mount.style.cursor = 'pointer';
            if (intersects[0].object !== this.INTERSECTED) {
                this.INTERSECTED = intersects[0].object;
                this.INTERSECTED.clicked = false;
                this.selectMachine();
            }
        } else {
            this.mount.style.cursor = 'default';
            if (this.INTERSECTED) {
                this.unselectMachine();
            }
            this.INTERSECTED = null;
        }
        this.controls.update();
    }

    selectMachine() {
        const machineINTERSECTED = this.INTERSECTED.userData as LayoutMachine;

        if (this.selectionMesh) {
            this.scene.remove(this.selectionMesh);
            this.setState({ showPanelLeft: false, showPanelBottom: false });
        }
        if (this.selectionStatusText) {
            this.scene.remove(this.selectionStatusText);
        }
        setTimeout(() => {
            if (!this.INTERSECTED) {
                this.setState({
                    showPanelLeft: false,
                    showPanelBottom: false,
                });
            } else {
                this.setState({
                    showPanelLeft: machineINTERSECTED.metric.length > 0,
                    showPanelBottom: machineINTERSECTED.contexts.length > 0 || machineINTERSECTED.mms !== null ||
                        MachineHelper.machineIsForSetupInspection(machineINTERSECTED, this.props),
                });
            }
        }, 150);

        const meshGeometry = this.getMeshGeometry(this.INTERSECTED);
        const geometry = new THREE.BoxGeometry(meshGeometry.width * 1.1, meshGeometry.height * 1.1, meshGeometry.depth * 1.1);
        this.selectionMesh = new THREE.Mesh(geometry, this.selectionMaterial);
        this.selectionMesh.position.set(this.INTERSECTED.position.x, this.INTERSECTED.position.y + meshGeometry.height / 2, this.INTERSECTED.position.z);
        this.selectionMesh.rotation.y = meshGeometry.rotationY;
        this.selectionMesh.renderOrder = 3 + machineINTERSECTED.floor * 10;
        this.scene.add(this.selectionMesh);
        this.showStatusText();
    }

    showStatusText() {
        const machine = this.INTERSECTED.userData as LayoutMachine;
        const meshGeometry = this.getMeshGeometry(this.INTERSECTED);
        if (machine.static || machine.status === 'UNKNOWN') {
            return;
        }
        const strkeColor = 'rgba(0,0,0,0.8)';
        let textObj = this.createTextObj(machine.status, { fontsize: this.props.options.fontSize, color: machine.color, strokeColor: strkeColor });
        if (this.props.options.use2DMode) {
            if (this.props.options.useHtmlLabels) {
                textObj.position.set(
                    this.INTERSECTED.position.x,
                    this.INTERSECTED.position.y,
                    this.INTERSECTED.position.z
                );
            } else {
                textObj.position.set(
                    this.INTERSECTED.position.x,
                    this.INTERSECTED.position.y + meshGeometry.height + 1,
                    this.INTERSECTED.position.z + (meshGeometry.depth / 2 - this.props.options.fontSize / 2)
                );
            }

        } else {
            textObj.position.set(
                this.INTERSECTED.position.x,
                this.INTERSECTED.position.y + meshGeometry.height + this.props.options.fontSize,
                this.INTERSECTED.position.z
            );
        }
        textObj.renderOrder = 5 + 10 * machine.floor;
        this.selectionStatusText = textObj;
        this.scene.add(textObj);
    }

    unselectMachine() {
        this.setState({ showPanelLeft: false, showPanelBottom: false });
        if (this.selectionMesh) {
            this.scene.remove(this.selectionMesh);
            this.selectionMesh = null;
        }
        if (this.selectionStatusText) {
            this.scene.remove(this.selectionStatusText);
            this.selectionStatusText = null;
        }
    }

    onDocumentMouseMove(e: any) {
        this.mouseHasMoved = true;
        if (this.mount) {
            const width = this.mount.clientWidth;
            const height = this.mount.clientHeight;
            this.mouse.x = (e.offsetX / width) * 2 - 1;
            this.mouse.y = -(e.offsetY / height) * 2 + 1;
        }
    }

    onTouchStart(e: any) {
        if (e.touches.length === 0) {
            return;
        }
        this.touchTimer = global.setTimeout(() => {
            this.onMouseDown(null);
            this.onMouseUp(null);
        }, this.touchDuration);
        const touch = e.touches[0] || e.changedTouches[0];
        const realTarget: Element = document.elementFromPoint(touch.clientX, touch.clientY) as Element;
        const bound: ClientRect = realTarget.getBoundingClientRect();
        e.offsetX = touch.clientX - bound.left;
        e.offsetY = touch.clientY - bound.top;

        this.mouseHasMoved = true;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.mouse.x = (e.offsetX / width) * 2 - 1;
        this.mouse.y = -(e.offsetY / height) * 2 + 1;
    }

    onTouchEnd(e: any) {
        if (this.touchTimer) {
            clearTimeout(this.touchTimer);
        }
    }

    UNSAFE_componentWillReceiveProps(props: Readonly<SceneProps>) {
        this.setState({
            showMaintenance: props.showMaintenance,
        }, () => {
            this.resize();
            if (props.selectedFloor !== undefined && props.selectedFloor !== this.currentFloor) {
                this.changeFloor(props.selectedFloor);
            } else {
                this.updateMachines(props);
            }
        });
    }

    updateMachines(props: Readonly<SceneProps>) {
        props.machines.forEach(machine => {
            const sceneMachine = this.machines.find(m => (m.userData as LayoutMachine).sensorId === machine.sensorId);
            if (sceneMachine === undefined) {
                //new machine
                this.addMachine(machine);
            } else {
                //update status
                sceneMachine.userData = machine;
                this.updateMachineStatus(sceneMachine);
            }
        });
    }

    updateMachineStatus(sceneMachine: THREE.Mesh) {
        const machine: LayoutMachine = sceneMachine.userData as LayoutMachine;
        sceneMachine.visible = machine.visible;

        if (this.props.options.use2DMode && machine.floor !== this.currentFloor) {
            sceneMachine.visible = false;
        }

        this.machineNumberSprites[machine.sensorId].visible = sceneMachine.visible;

        if (machine.static) {
            return;
        }
        if (this.props.options.showBase) {
            //base
            if (this.machineBases[machine.sensorId]) {
                this.machineBases[machine.sensorId].visible = machine.status !== 'UNKNOWN' && sceneMachine.visible;
                this.machineBases[machine.sensorId].material.color.set(machine.color);
                if (this.props.options.emissiveStatusLight) {
                    this.machineBases[machine.sensorId].material.emissive.set(machine.color);
                } else {
                    this.machineBases[machine.sensorId].material.emissive.set(0x000000);
                }
            }
        } else {
            if (machine.status !== 'UNKNOWN') {
                (sceneMachine.material as any).color.set(machine.color);
                const segments = sceneMachine.children[1] as THREE.LineSegments;
                if (segments) {
                    (segments.material as any).color.set(machine.color);
                }
            } else {
                (sceneMachine.material as any).color.set(this.props.options.machineColor);
                const segments = sceneMachine.children[1] as THREE.LineSegments;
                if (segments) {
                    (segments.material as any).color.set(this.props.options.machineColor);
                }
            }
        }

        if (machine.path) {
            if (this.glbMachineStatusBases[machine.sensorId]) {
                this.glbMachineStatusBases[machine.sensorId].visible = machine.status !== 'UNKNOWN' && sceneMachine.visible;
                this.glbMachineStatusBases[machine.sensorId].material.color.set(machine.color);
                if (this.props.options.emissiveStatusLight) {
                    this.glbMachineStatusBases[machine.sensorId].material.emissive.set(machine.color);
                } else {
                    this.glbMachineStatusBases[machine.sensorId].material.emissive.set(0x000000);
                }
            }
            //Add Animation Logic
            const animationData = this.machineAnimations[machine.sensorId];
            if (animationData && animationData.animations && animationData.configuration) {
                animationData.configuration.forEach((conf: any) => {
                    const animation = animationData.animations[conf.id];
                    const clipAnimation = animationData.mixer.clipAction(animation);
                    if (conf.status && machine.status && conf.status.length > 0 && conf.status.includes(machine.status)) {
                        if (!clipAnimation.isRunning()) {
                            clipAnimation.play();
                        }
                    }
                    else {
                        if (clipAnimation.isRunning()) {
                            clipAnimation.stop();
                        }
                    }
                });
            }
        }

        //lamp
        if (this.machineLamps[machine.sensorId]) {
            this.machineLamps[machine.sensorId].visible = machine.status !== 'UNKNOWN' && sceneMachine.visible;
            this.machineLampBases[machine.sensorId].visible = machine.status !== 'UNKNOWN' && sceneMachine.visible;
            this.machineLamps[machine.sensorId].material.color.set(machine.color);
            if (this.props.options.emissiveStatusLight) {
                this.machineLamps[machine.sensorId].material.emissive.set(machine.color);
            } else {
                this.machineLamps[machine.sensorId].material.emissive.set(0x000000);
            }
        }

        //alarm icon, wrench, etc...
        this.setIconsOvermachine(sceneMachine, machine);
        this.setDisplayedMetrix(sceneMachine, machine);

    }

    setDisplayedMetrix(sceneMachine: THREE.Mesh, machine: LayoutMachine) {
        if (this.machineMetricSprites[machine.sensorId] && this.machineMetricSprites[machine.sensorId].length > 0) {
            this.machineMetricSprites[machine.sensorId].forEach((sprite: THREE.Object3D) => {
                this.scene.remove(sprite);
            });
        }
        this.machineMetricSprites[machine.sensorId] = [];
        if (sceneMachine.visible) {
            machine.metric.forEach(metric => {
                const metricDefinition = metric.definition;
                if (metricDefinition.displayOnMachine) {
                    let textObj = this.createTextObj(metric.value + metric.definition.unit, { fontsize: metricDefinition.fontSize, color: metricDefinition.color });

                    const position = this.getMetrixPositionOnMachine(sceneMachine, metricDefinition.positionOnMachine, metricDefinition.fontSize);
                    textObj.position.copy(position);
                    textObj.renderOrder = 4 + machine.floor * 10;
                    this.scene.add(textObj);
                    this.machineMetricSprites[machine.sensorId].push(textObj);
                }
            });
        }
    }

    getMetrixPositionOnMachine(sceneMachine: THREE.Mesh, position: PositionOnMachine, fontsize: number): THREE.Vector3 {
        let positionVector = new THREE.Vector3();
        const meshGeometry = this.getMeshGeometry(sceneMachine);

        if (this.props.options.use2DMode) {
            let y;
            if (this.props.options.useHtmlLabels) {
                y = sceneMachine.position.y;
                switch (position) {
                    case PositionOnMachine.TopLeft:
                        positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, y, sceneMachine.position.z - meshGeometry.depth / 2 + fontsize);
                        break;
                    case PositionOnMachine.TopCenter:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z - meshGeometry.depth / 2 + fontsize);
                        break;
                    case PositionOnMachine.TopRight:
                        positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, y, sceneMachine.position.z - meshGeometry.depth / 2 + fontsize);
                        break;
                    case PositionOnMachine.MiddleLeft:
                        positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, y, sceneMachine.position.z + fontsize);
                        break;
                    case PositionOnMachine.MiddleCenter:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z + fontsize);
                        break;
                    case PositionOnMachine.MiddleRight:
                        positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, y, sceneMachine.position.z + fontsize);
                        break;
                    case PositionOnMachine.BottomLeft:
                        positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, y, sceneMachine.position.z + meshGeometry.depth / 2 + fontsize / 2);
                        break;
                    case PositionOnMachine.BottomCenter:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z + meshGeometry.depth / 2 + fontsize / 2);
                        break;
                    case PositionOnMachine.BottomRight:
                        positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, y, sceneMachine.position.z + meshGeometry.depth / 2 + fontsize / 2);
                        break;
                    default:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z);
                        break;
                }
            }
            else {
                y = sceneMachine.position.y + meshGeometry.depth + fontsize / 2;
                switch (position) {
                    case PositionOnMachine.TopLeft:
                        positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, y, sceneMachine.position.z);
                        break;
                    case PositionOnMachine.TopCenter:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z);
                        break;
                    case PositionOnMachine.TopRight:
                        positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, y, sceneMachine.position.z);
                        break;
                    case PositionOnMachine.MiddleLeft:
                        positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, y, sceneMachine.position.z + meshGeometry.depth / 2);
                        break;
                    case PositionOnMachine.MiddleCenter:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z + meshGeometry.depth / 2);
                        break;
                    case PositionOnMachine.MiddleRight:
                        positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, y, sceneMachine.position.z + meshGeometry.depth / 2);
                        break;
                    case PositionOnMachine.BottomLeft:
                        positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, y, sceneMachine.position.z + meshGeometry.depth);
                        break;
                    case PositionOnMachine.BottomCenter:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z + meshGeometry.depth);
                        break;
                    case PositionOnMachine.BottomRight:
                        positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, y, sceneMachine.position.z + meshGeometry.depth);
                        break;
                    default:
                        positionVector = new THREE.Vector3(sceneMachine.position.x, y, sceneMachine.position.z);
                        break;
                }
            }
        } else {
            const z = sceneMachine.position.z + meshGeometry.depth / 2 + fontsize / 2;
            switch (position) {
                case PositionOnMachine.TopLeft:
                    positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, sceneMachine.position.y + meshGeometry.height / 2, z);
                    break;
                case PositionOnMachine.TopCenter:
                    positionVector = new THREE.Vector3(sceneMachine.position.x, sceneMachine.position.y + meshGeometry.height / 2, z);
                    break;
                case PositionOnMachine.TopRight:
                    positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, sceneMachine.position.y + meshGeometry.height / 2, z);
                    break;
                case PositionOnMachine.MiddleLeft:
                    positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, sceneMachine.position.y, z);
                    break;
                case PositionOnMachine.MiddleCenter:
                    positionVector = new THREE.Vector3(sceneMachine.position.x, sceneMachine.position.y, z);
                    break;
                case PositionOnMachine.MiddleRight:
                    positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, sceneMachine.position.y, z);
                    break;
                case PositionOnMachine.BottomLeft:
                    positionVector = new THREE.Vector3(sceneMachine.position.x - meshGeometry.width / 2, sceneMachine.position.y - meshGeometry.height / 2, z);
                    break;
                case PositionOnMachine.BottomCenter:
                    positionVector = new THREE.Vector3(sceneMachine.position.x, sceneMachine.position.y - meshGeometry.height / 2, z);
                    break;
                case PositionOnMachine.BottomRight:
                    positionVector = new THREE.Vector3(sceneMachine.position.x + meshGeometry.width / 2, sceneMachine.position.y - meshGeometry.height / 2, z);
                    break;
                default:
                    positionVector = new THREE.Vector3(sceneMachine.position.x, sceneMachine.position.y, z);
                    break;
            }
        }

        return positionVector;
    }

    setIconsOvermachine(sceneMachine: THREE.Mesh, machine: LayoutMachine) {
        //set indicators on top
        //planned maintenance
        let showPlannedMaintenance = false;
        if (!machine.restrictedAccess &&
            (machine.mms
                && ((machine.mms as MMSData).shutdownRequired || this.props.options.showAllMaintenance)
                && (this.props.showMaintenance || (machine.mms as MMSData).started)
                && !(machine.mms as MMSData).history)) {
            showPlannedMaintenance = true;
        }
        let wrenchColor = null;
        if (showPlannedMaintenance) {
            wrenchColor = (machine.mms as MMSData).started
                ? this.props.options.startedMaintenanceColor
                : this.props.options.plannedMaintenanceColor;
        }
        this.setMachineIndicator(this.wrenchObject, showPlannedMaintenance, this.wrenchDic, machine, sceneMachine, wrenchColor);

        //alarms
        const showAlarm: boolean = machine.alarm && !showPlannedMaintenance && !machine.restrictedAccess;
        this.setMachineIndicator(this.alarmObject, showAlarm, this.alarmsDic, machine, sceneMachine);

        //restricted access
        const showRestrictedAccess: boolean = machine.restrictedAccess;
        this.setMachineIndicator(this.restrictedAccessObject, showRestrictedAccess, this.restrictedAccessDic, machine, sceneMachine,
            this.props.options.restrictedAccessColor, true, 'cornerNTR');
    }

    setMachineIndicator(
        object: any, doShow: boolean, machineObjectDict: any, machine: LayoutMachine, sceneMachine: THREE.Mesh,
        color: any = null, emissive = false, atCorner: any = null
    ) {
        if (!object) {
            return;
        }

        if (doShow) {
            if (machineObjectDict[machine.sensorId]) {
                machineObjectDict[machine.sensorId].visible = sceneMachine.visible;
            } else {
                const indicator = object.clone() as Scene;
                const meshGeometry = this.getMeshGeometry(sceneMachine);

                let x = sceneMachine.position.x;
                let y = sceneMachine.position.y + meshGeometry.height + this.props.options.lampSize * 4;
                let z = sceneMachine.position.z;

                if (this.props.options.use2DMode) {
                    y = sceneMachine.position.y + meshGeometry.height;
                }

                if (atCorner) {
                    type MeshDimensionsKey = keyof typeof meshGeometry;
                    const prop = atCorner as MeshDimensionsKey;
                    x = (meshGeometry[prop] as Vector3).x;
                    y = (meshGeometry[prop] as Vector3).y;
                    z = (meshGeometry[prop] as Vector3).z;
                }

                indicator.position.set(x, y, z);

                if (this.props.options.use2DMode) {
                    //rotate indicator by 90 degrees on X axis
                    const pivot = new THREE.Group();
                    pivot.position.copy(indicator.position);
                    this.scene.add(pivot);
                    indicator.position.set(0, 0, 0);
                    pivot.add(indicator);
                    pivot.rotateX(-Math.PI / 2);
                    this.scene.attach(indicator);
                    this.scene.remove(pivot);
                } else {
                    this.scene.add(indicator);
                }

                machineObjectDict[machine.sensorId] = indicator;
                machineObjectDict[machine.sensorId].visible = sceneMachine.visible;
            }
            if (color) {
                (machineObjectDict[machine.sensorId] as THREE.Scene).children.forEach(element => {
                    const material = ((element as THREE.Mesh).material as THREE.MeshStandardMaterial).clone();
                    material.color.set(color);
                    if (emissive) {
                        material.emissive.set(color);
                    }
                    (element as THREE.Mesh).material = material;
                });
            }
        } else {
            if (machineObjectDict[machine.sensorId]) {
                this.scene.remove(machineObjectDict[machine.sensorId]);
                machineObjectDict[machine.sensorId] = null;
            }
        }
    }

    resize() {
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        if (width !== this.state.width || height !== this.state.height) {
            this.setState({ width: width, height: height });
            this.renderer.setSize(width, height);
            this.labelRenderer.setSize(width, height);
            this.camera.aspect = width / height;
            //reset position
            if (this.props.options.actualCameraPosition !== null && this.props.options.actualCameraPosition !== undefined) {
                this.camera.position.set(this.props.options.actualCameraPosition.x, this.props.options.actualCameraPosition.y, this.props.options.actualCameraPosition.z);
            } else {
                this.camera.position.set(this.props.options.cameraPosition.x, this.props.options.cameraPosition.y, this.props.options.cameraPosition.z);
            }

            if (this.props.options.use2DMode) {
                this.controls.target.set(this.camera.position.x, 10, this.camera.position.z);
            } else {
                if (this.props.options.actualControlsTargetPosition !== null && this.props.options.actualControlsTargetPosition !== undefined) {
                    this.controls.target.set(this.props.options.actualControlsTargetPosition.x, this.props.options.actualControlsTargetPosition.y, this.props.options.actualControlsTargetPosition.z);
                } else {
                    this.controls.target.set(this.props.layoutConfiguration.mapWidth / 2, 10, this.props.layoutConfiguration.mapHeight / 2 - this.props.options.floorOffset);
                }
            }

            this.camera.updateProjectionMatrix();
        }
    }

    fullscreenResize() {
        setTimeout(() => {
            const width = this.mount.clientWidth;
            const height = this.mount.clientHeight;

            console.log('W=' + width + ' H=' + height);

            if (width !== this.state.width || height !== this.state.height) {
                this.setState({ width: width, height: height });
                this.renderer.setSize(width, height);
                this.labelRenderer.setSize(width, height);
                if (this.camera instanceof THREE.PerspectiveCamera) {
                    this.camera.aspect = width / height;
                }
                this.camera.updateProjectionMatrix();
            }
        }, 150);
    }

    componentDidMount() {
        this.initializeScene();
        //EVENTS
        document.addEventListener('mousemove', e => this.onDocumentMouseMove(e), false);
        document.addEventListener('mousedown', e => this.onMouseDown(e), false);
        document.addEventListener('mouseup', e => this.onMouseUp(e), false);
        document.addEventListener('keydown', e => this.onKeyPress(e), false);
        //touch
        document.addEventListener('touchstart', e => this.onTouchStart(e), false);
        document.addEventListener('touchend', e => this.onTouchEnd(e), false);
        //fullscreen
        if (screenfull.isEnabled) {
            this.fullscreenResize = this.fullscreenResize.bind(this);
            screenfull.onchange(this.fullscreenResize);
        }
    }

    initializeScene() {
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        this.setState({ width: width, height: height });
        //ADD SCENE
        this.scene = new THREE.Scene();
        //ADD CAMERA       
        this.camera = new THREE.PerspectiveCamera(30, width / height, 1, 20000);

        console.log(this.props.options.actualCameraPosition);
        if (this.props.options.actualCameraPosition !== null && this.props.options.actualCameraPosition !== undefined) {
            this.camera.position.set(this.props.options.actualCameraPosition.x, this.props.options.actualCameraPosition.y, this.props.options.actualCameraPosition.z);
        } else {
            this.camera.position.set(this.props.options.cameraPosition.x, this.props.options.cameraPosition.y, this.props.options.cameraPosition.z);
        }
        this.currentFloor = this.props.options.defaultFloor;
        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.shadowMap.enabled = true;
        this.renderer.setClearColor('#000000');
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement);
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(width, height);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        this.mount.appendChild(this.labelRenderer.domElement);

        //CONTROLS
        this.controls = new OrbitControls(this.camera, this.labelRenderer.domElement);
        if (this.props.options.use2DMode) {
            this.controls.mouseButtons = {
                LEFT: THREE.MOUSE.PAN,  // Left mouse button pans
                MIDDLE: THREE.MOUSE.DOLLY, // Middle mouse button (wheel) zooms
                RIGHT: THREE.MOUSE.PAN // Right mouse button orbits
            };
        }
        this.controls.maxPolarAngle = Math.PI * 0.5;
        this.controls.zoomSpeed = 1;
        this.controls.minDistance = 100;
        this.controls.maxDistance = (this.props.layoutConfiguration.mapWidth + this.props.layoutConfiguration.mapHeight) * 2;

        if (this.props.options.use2DMode) {
            this.controls.target.set(this.camera.position.x, 10, this.camera.position.z);
        } else {
            if (this.props.options.actualControlsTargetPosition !== null && this.props.options.actualControlsTargetPosition !== undefined) {
                this.controls.target.set(this.props.options.actualControlsTargetPosition.x, this.props.options.actualControlsTargetPosition.y, this.props.options.actualControlsTargetPosition.z);
            } else {
                this.controls.target.set(this.props.layoutConfiguration.mapWidth / 2, 10, this.props.layoutConfiguration.mapHeight / 2 - this.props.options.floorOffset);
            }
        }

        //ADD CLOCK
        this.clock = new THREE.Clock();

        //damping
        //this.controls.screenSpacePanning = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.1;
        this.controls.update();
        //OTHER
        this.getProxyUrl().then(r => {
            this.addLights();
            this.addBackground();
            this.loadAlarmObject();
            this.loadWrenchObject();
            this.loadRestrictedAccessObject();
            this.addMachines(this.props.machines);
        })
        this.addGrid();
        this.start();
    }

    getProxyUrl() {
        const publicApiName = 'public-api';
        const srv = getDataSourceSrv();
        return srv.get(publicApiName).then(response => {
            const us = response as any;
            this.proxyUrl = `/api/datasources/${us.id}/resources`;
        });
    }

    addGrid() {
        if (!this.props.options.grid) {
            return;
        }
        const size = ((this.props.layoutConfiguration.mapHeight + this.props.layoutConfiguration.mapHeight) / 2) * 10;
        const divisions = 100;
        const gridHelper = new THREE.GridHelper(
            size,
            divisions,
            new THREE.Color(this.props.options.gridColor),
            new THREE.Color(this.props.options.gridColor)
        );
        gridHelper.position.set(this.props.layoutConfiguration.mapWidth / 2, -20, this.props.layoutConfiguration.mapHeight / 2);
        this.scene.add(gridHelper);
    }

    addMachines(machines: LayoutMachine[]) {
        machines.forEach(machine => {
            this.addMachine(machine);
            if (machine.path && !this.props.options.disable3dModels) {
                this.loadMachineGlb(machine);
            }
        });
    }

    loadMachineGlb(machine: LayoutMachine) {
        const URL = window.location.origin + this.proxyUrl + '/publicapi/api/FactoryLayout/GetModelUrl/' + machine.path;

        axios.get(URL).then(
            r => {
                const loader = new GLTFLoader();
                loader.setDRACOLoader(this.dracoLoader);

                loader.load(r.data,
                    gltf => {
                        this.handleGlbLoaded(machine, gltf)
                    },
                    undefined,
                    error => console.error(error)
                );
            },
            e => {
                console.log(e);
            }
        );
    }

    handleGlbLoaded(machine: LayoutMachine, modelObject: any) {
        const floorOffset = machine.floor * this.props.options.floorOffset;
        const floorHeight = machine.floor * this.props.options.floorHeight;
        const mesh = this.createMachineMeshWrapper(machine, modelObject.scene);

        mesh.castShadow = false;
        mesh.userData = machine;
        mesh.renderOrder = 1 + machine.floor * 10;

        mesh.scale.set(machine.scale, machine.scale, machine.scale);
        this.addGlbMachineStatusBase(mesh, machine);
        let machineYPosition = floorHeight + 2;
        if (machine.posZ !== null && machine.posZ > 0) {
            machineYPosition += machine.posZ;
        }
        mesh.position.set(machine.posX * this.props.layoutConfiguration.mapWidth, machineYPosition, machine.posY * this.props.layoutConfiguration.mapHeight - floorOffset);

        this.addMachineAnimation(machine, modelObject);
        this.addMachineNumber(mesh);
        if (!machine.static) {
            this.addMachineLamp(mesh, machine);
            this.updateMachineStatus(mesh);
        }

        if (this.machineMeshes[machine.sensorId]) {
            //remove basic machine box
            const oldMachine = this.machineMeshes[machine.sensorId];
            this.scene.remove(oldMachine);
            const machineIndex = this.machines.indexOf(oldMachine);
            this.machines.splice(machineIndex, 1);
        }
        this.machines.push(mesh);
        this.machineMeshes[machine.sensorId] = mesh;
        this.scene.add(mesh);
    }

    addMachineAnimation(machine: LayoutMachine, modelObject: any) {
        const machineHasAnimations = modelObject.animations && modelObject.animations.length > 0;
        if (machineHasAnimations) {
            const mixer = new THREE.AnimationMixer(modelObject.scene);
            this.machineAnimations[machine.sensorId] = {
                animations: modelObject.animations,
                configuration: machine.animations,
                mixer: mixer
            };
        }
    }

    createMachineMeshWrapper(machine: LayoutMachine, mesh: THREE.Mesh) {
        const meshGeometry = this.getMeshGeometry(mesh);
        mesh.position.set(
            - meshGeometry.center.x,
            - 0,
            - meshGeometry.center.z
        );

        const geometry = new THREE.BoxGeometry(meshGeometry.width, meshGeometry.height, meshGeometry.depth);
        const material = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            opacity: 0,
            transparent: true
        });
        const cube = new THREE.Mesh(geometry, material);
        cube.rotation.y = THREE.Math.degToRad(machine.rotate);
        cube.add(mesh);

        return cube;
    }

    getMeshGeometry(mesh: THREE.Mesh): MeshGeometry {
        const meshClone = mesh.clone();

        const gltfboxRotated = new THREE.Box3().setFromObject(meshClone);
        const center = gltfboxRotated.getCenter(new Vector3());
        const lowRotated = gltfboxRotated.min;
        const highRotated = gltfboxRotated.max;

        const rotationY = mesh.rotation.y;
        meshClone.rotation.y = 0;
        const gltfboxNoRotation = new THREE.Box3().setFromObject(meshClone);
        const objectwidth = gltfboxNoRotation.getSize(new Vector3()).x;
        const objectheight = gltfboxNoRotation.getSize(new Vector3()).y;
        const objectdepth = gltfboxNoRotation.getSize(new Vector3()).z;

        const res: MeshGeometry = {
            width: objectwidth,
            height: objectheight,
            depth: objectdepth,
            rotationY: rotationY,
            center: center,

            //F=far, N=near, L=left, R=right

            cornerFBR: new THREE.Vector3(lowRotated.x, lowRotated.y, lowRotated.z),
            cornerFBL: new THREE.Vector3(highRotated.x, lowRotated.y, lowRotated.z),
            cornerNBL: new THREE.Vector3(lowRotated.x, highRotated.y, lowRotated.z),
            cornerNBR: new THREE.Vector3(lowRotated.x, lowRotated.y, highRotated.z),

            cornerFTR: new THREE.Vector3(highRotated.x, highRotated.y, lowRotated.z),
            cornerFTL: new THREE.Vector3(highRotated.x, lowRotated.y, highRotated.z),
            cornerNTL: new THREE.Vector3(lowRotated.x, highRotated.y, highRotated.z),
            cornerNTR: new THREE.Vector3(highRotated.x, highRotated.y, highRotated.z)
        }
        return res;
    }

    addMachine(machine: LayoutMachine) {
        const floorOffset = machine.floor * this.props.options.floorOffset;
        const floorHeight = machine.floor * this.props.options.floorHeight;

        const machineZOffset = machine.posZ;
        let machineYPosition = floorHeight + 2;
        if (machineZOffset !== null && machineZOffset > 0) {
            machineYPosition += machineZOffset;
        }

        const mesh = this.makeMachineMesh(machine);
        mesh.position.set(machine.posX * this.props.layoutConfiguration.mapWidth, machineYPosition, machine.posY * this.props.layoutConfiguration.mapHeight - floorOffset);

        // wireframe
        const geo = new THREE.EdgesGeometry(mesh.geometry); // or WireframeGeometry
        const mat = new THREE.LineBasicMaterial({ color: this.props.options.machineColor, linewidth: 4 });
        const wireframe = new THREE.LineSegments(geo, mat);
        mesh.add(wireframe);

        if (machine.face === 'top') {
            this.machineImages[machine.sensorId].position.setY(machine.depth / 2);
            this.machineImages[machine.sensorId].position.setZ(machine.depth / 2 + 1);
        } else {
            this.machineImages[machine.sensorId].position.setZ(machine.depth / 2 + 1);
        }
        this.addMachineNumber(mesh);

        if (machine.hasImage) {
            this.loadMachineImage(mesh);
        }
        else {
            this.loadDefaultMachineImage(mesh);
        }

        if (!machine.static) {
            this.addMachineBase(mesh, machine);
            this.addMachineLamp(mesh, machine);
            this.updateMachineStatus(mesh);
        }

        this.machines.push(mesh);
        if (machine.path) {
            this.machineMeshes[machine.sensorId] = mesh;
        }
        this.scene.add(mesh);
    }

    addMachineBase(mesh: THREE.Mesh, machine: LayoutMachine) {
        if (!this.props.options.showBase) {
            return;
        }
        const geometry = new THREE.BoxGeometry(machine.width + this.props.options.baseSize, 4, machine.depth + this.props.options.baseSize);
        const baseMesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
        baseMesh.position.setY(baseMesh.position.y - 2);
        baseMesh.castShadow = false;
        if (this.machineBases[machine.sensorId]) {
            this.scene.remove(this.machineBases[machine.sensorId]);
        }
        this.machineBases[machine.sensorId] = baseMesh;
        mesh.add(baseMesh);
    }

    addGlbMachineStatusBase(mesh: THREE.Mesh, machine: LayoutMachine) {
        if (!machine.orgItemId) { return; }
        const floorOffset = machine.floor * this.props.options.floorOffset;
        const floorHeight = machine.floor * this.props.options.floorHeight;
        const meshGeometry = this.getMeshGeometry(mesh);
        const geometry = new THREE.BoxGeometry(meshGeometry.width + 4, 4, meshGeometry.depth + 4);
        const baseMesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
        baseMesh.rotation.y = meshGeometry.rotationY;
        let machineYPosition = floorHeight;
        if (machine.posZ !== null && machine.posZ > 0) {
            machineYPosition += machine.posZ;
        }

        baseMesh.position.set(machine.posX * this.props.layoutConfiguration.mapWidth, machineYPosition, machine.posY * this.props.layoutConfiguration.mapHeight - floorOffset);
        baseMesh.castShadow = false;
        if (this.glbMachineStatusBases[machine.sensorId]) {
            this.scene.remove(this.glbMachineStatusBases[machine.sensorId]);
        }
        this.glbMachineStatusBases[machine.sensorId] = baseMesh;
        this.scene.add(baseMesh);
    }

    addMachineLamp(mesh: THREE.Mesh, machine: LayoutMachine) {
        if (!this.props.options.showLamp) {
            return;
        }
        const geometry = new THREE.CylinderGeometry(this.props.options.lampSize, this.props.options.lampSize, this.props.options.lampSize * 2);
        geometry.translate(0, machine.height + this.props.options.lampSize, 0);
        const lampMesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
        lampMesh.position.copy(mesh.position);
        lampMesh.castShadow = false;
        this.machineLamps[machine.sensorId] = lampMesh;
        this.scene.add(lampMesh);
        //lamp base
        const baseGeometry = new THREE.CylinderGeometry(
            this.props.options.lampSize * 1.2,
            this.props.options.lampSize * 1.2,
            this.props.options.lampSize * 0.2
        );
        baseGeometry.translate(0, machine.height + this.props.options.lampSize * 0.1, 0);
        const baseMesh = new THREE.Mesh(baseGeometry, new THREE.MeshLambertMaterial({ color: '#5A5A5A' }));
        baseMesh.position.copy(mesh.position);
        baseMesh.castShadow = false;
        if (this.machineLampBases[machine.sensorId]) {
            this.scene.remove(this.machineLampBases[machine.sensorId])
        }
        this.machineLampBases[machine.sensorId] = baseMesh;
        this.scene.add(baseMesh);
    }

    makeMachineMesh(machine: LayoutMachine): THREE.Mesh {
        const width = machine.width,
            height = machine.height,
            depth = machine.depth;
        const geometry = new THREE.BoxGeometry(width, height, depth);
        switch (machine.face) {
            case 'west':
                this.machineImages[machine.sensorId] = new THREE.Mesh(
                    new THREE.BoxGeometry(1, height, depth).translate(-width / 2 - 1, height / 2, -depth / 2),
                    new THREE.MeshLambertMaterial({ color: '#ffffff' })
                );
                break;
            case 'north':
                this.machineImages[machine.sensorId] = new THREE.Mesh(
                    new THREE.BoxGeometry(width, height, 1).translate(0, height / 2, -depth - 1),
                    new THREE.MeshLambertMaterial({ color: '#ffffff' })
                );
                break;
            case 'east':
                this.machineImages[machine.sensorId] = new THREE.Mesh(
                    new THREE.BoxGeometry(1, height, depth).translate(width / 2, height / 2, -depth / 2),
                    new THREE.MeshLambertMaterial({ color: '#ffffff' })
                );
                break;
            case 'top':
                this.machineImages[machine.sensorId] = new THREE.Mesh(
                    new THREE.BoxGeometry(width, depth, 1).translate(0, 0, -depth / 2),
                    new THREE.MeshLambertMaterial({ color: '#ffffff' })
                );
                break;
            default:
                this.machineImages[machine.sensorId] = new THREE.Mesh(
                    new THREE.BoxGeometry(width, height, 1).translate(0, height / 2, 0),
                    new THREE.MeshLambertMaterial({ color: '#ffffff' })
                );
                break;
        }

        this.machineImages[machine.sensorId].renderOrder = 2 + machine.floor * 10;
        geometry.translate(0, height / 2, 0);
        const material = new THREE.MeshPhongMaterial({
            color: this.props.options.machineColor,
            transparent: true,
            opacity: this.props.options.machineOpacity,
            polygonOffset: true,
            polygonOffsetFactor: 1, // positive value pushes polygon further away
            polygonOffsetUnits: 1,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.rotation.y = THREE.Math.degToRad(machine.rotate);
        mesh.castShadow = false;
        mesh.userData = machine;
        mesh.renderOrder = 1 + machine.floor * 10;
        mesh.add(this.machineImages[machine.sensorId]);
        return mesh;
    }

    createTextObj(message: string, opts: any): THREE.Object3D {
        if (this.props.options.useHtmlLabels) {
            return this.createText2d(message, opts);
        } else {
            return this.makeTextSprite(message, opts);
        }
    }

    addMachineNumber(mesh: THREE.Mesh) {
        const machine = mesh.userData as LayoutMachine;
        let textObj = this.createTextObj(machine.name, { fontsize: this.props.options.fontSize });

        const meshGeometry = this.getMeshGeometry(mesh);
        const adjustedHeight = meshGeometry.height > 1 ? meshGeometry.height : meshGeometry.depth;

        if (this.props.options.use2DMode) {
            if (this.props.options.useHtmlLabels) {
                textObj.position.set(mesh.position.x, mesh.position.y, mesh.position.z - meshGeometry.depth / 2);
            } else {
                textObj.position.set(mesh.position.x, mesh.position.y + adjustedHeight * 1.1, mesh.position.z - (this.props.options.fontSize / 2));
            }
        } else {
            textObj.position.set(mesh.position.x, mesh.position.y + adjustedHeight * 1.1, mesh.position.z);
        }
        textObj.renderOrder = 4 + machine.floor * 10;
        if (this.machineNumberSprites[machine.sensorId]) {
            this.scene.remove(this.machineNumberSprites[machine.sensorId]);
        }
        this.machineNumberSprites[machine.sensorId] = textObj;
        this.scene.add(textObj);
    }

    makeTextSprite(message: string, opts: any): THREE.Sprite {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        const fontsize = opts.hasOwnProperty('fontsize') ? opts['fontsize'] : 50;
        ctx.font = 'Bold ' + fontsize + 'px Arial';
        ctx.strokeStyle = opts.hasOwnProperty('strokeColor') ? opts['strokeColor'] : 'rgba(0,0,0,0.8)';
        ctx.lineWidth = 4;
        ctx.textAlign = 'center';
        ctx.strokeText(message, 150, fontsize, 300);
        ctx.fillStyle = opts.hasOwnProperty('color') ? opts['color'] : 'rgba(255,255,255,0.9)';
        ctx.fillText(message, 150, fontsize, 300);
        const texture1 = new THREE.Texture(canvas);
        texture1.minFilter = THREE.LinearFilter;
        texture1.needsUpdate = true;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: texture1,
            transparent: true,
            side: THREE.DoubleSide,
            depthWrite: false,
        });
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(3 * fontsize, 2 * fontsize, 1);
        return sprite;
    }

    createText2d(text: string, opts: any): CSS2DObject {
        const textDiv = document.createElement('div');
        textDiv.className = 'label';
        textDiv.textContent = text;
        textDiv.style.backgroundColor = 'transparent';
        const fontsize = opts.hasOwnProperty('fontsize') ? opts['fontsize'] : 18;
        textDiv.style.fontSize = fontsize + 'px';
        textDiv.style.color = opts.hasOwnProperty('color') ? opts['color'] : 'rgba(255,255,255,0.9)';
        textDiv.style.pointerEvents = 'none';
        textDiv.style.top = '-' + fontsize + 'px';
        const earthLabel = new CSS2DObject(textDiv);
        return earthLabel;
    }

    componentWillUnmount() {
        this.stop();
        this.mount.removeChild(this.renderer.domElement);
        this.mount.removeChild(this.labelRenderer.domElement);
    }

    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    };

    stop = () => {
        cancelAnimationFrame(this.frameId);
    };

    animate = () => {
        if (this.machineAnimations) {
            const delta = this.clock.getDelta();
            for (let i in this.machineAnimations) {
                const animation = this.machineAnimations[i];
                animation.mixer.update(delta);
            }
        }

        this.controls.update();
        this.setObjectsBehaviour();
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate);
        this.updateOnMouseMove();
        TWEEN.update();
    };

    renderScene = () => {
        this.renderer.render(this.scene, this.camera);
        this.labelRenderer.render(this.scene, this.camera);
    };

    addBackground() {
        let isDark = config.theme.isDark;
        if(isDark){
            this.scene.background = new THREE.Color(this.props.options.backgroundColor);
        }else{
            this.scene.background = new THREE.Color(this.props.options.backgroundColorLight);
        }

        this.scene.fog = new THREE.Fog(0x000000, 500, ((this.props.layoutConfiguration.mapHeight + this.props.layoutConfiguration.mapHeight) / 2) * 10);
        // Map surface
        const loader = new THREE.TextureLoader();
        if (this.props.layoutConfiguration != null) {
            this.props.layoutConfiguration.floors.forEach(v => {
                if (!v) {
                    return;
                }
                const URL = window.location.origin + this.proxyUrl + '/publicapi/api/FactoryLayout/GetModelUrl' + v.url;
                axios.get(URL).then(
                    r => {
                        loader.load(
                            r.data,
                            texture => {
                                texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
                                texture.magFilter = THREE.NearestFilter;
                                texture.minFilter = THREE.NearestFilter;
                                this.loadMapTexture(texture, v.floor);
                            },
                            undefined,
                            err => {
                                console.log(err);
                            }
                        );
                    },
                    e => {
                        console.log(e);
                    }
                );
            });
        }

    }

    loadMapTexture(texture: any, level: number) {
        texture.needsUpdate = true;
        const groundMaterial = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
            depthWrite: false,
        });
        const mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.props.layoutConfiguration.mapWidth, this.props.layoutConfiguration.mapHeight), groundMaterial);
        mesh.position.y = 0 + level * this.props.options.floorHeight;
        mesh.position.x = this.props.layoutConfiguration.mapWidth / 2;
        mesh.position.z = this.props.layoutConfiguration.mapHeight / 2 - level * this.props.options.floorOffset;
        mesh.rotation.x = -Math.PI / 2;
        mesh.receiveShadow = true;
        mesh.renderOrder = level * 10;
        if (this.props.options.use2DMode && this.currentFloor !== level) {
            mesh.visible = false;
        }
        this.floors[level] = mesh;
        this.scene.add(mesh);
    }

    logCameraPosition() {
        console.log("Camera position: x=" + this.camera.position.x + "; y=" + this.camera.position.y + "; z=" + this.camera.position.z);
        console.log("Orbit Controls position: x=" + this.controls.target.x + "; y=" + this.controls.target.y + "; z=" + this.controls.target.z);
    }

    addLights() {
        const d = this.props.layoutConfiguration.mapWidth + this.props.layoutConfiguration.mapHeight;
        this.scene.add(new THREE.AmbientLight(0x666666, 0.6));
        const light = new THREE.DirectionalLight(0xdfebff, 0.8);
        light.position.set(1500, 1700, 700);
        light.castShadow = false;
        light.shadow.mapSize.width = 4024;
        light.shadow.mapSize.height = 4024;

        light.shadow.camera.left = -d;
        light.shadow.camera.right = d;
        light.shadow.camera.top = d;
        light.shadow.camera.bottom = -d;
        light.shadow.camera.far = d;
        this.scene.add(light);
        const hemiLight = new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 0.2);
        this.scene.add(hemiLight);
    }
}
export default ThreeScene;
