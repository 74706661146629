import React, { Component } from 'react';
import { MenuProps } from 'types';
import screenfull from 'screenfull';

class Menu extends Component<MenuProps> {
  constructor(props: Readonly<MenuProps>) {
    super(props);

    this.state = {
      filterListDepartment: props.filterListDepartment,
      filterListTeam: props.filterListTeam,
      fullscreenTargetId: props.fullscreenTargetId,
      floorList: props.floorList,
      selectedDepartment: props.selectedDepartment,
      selectedTeam: props.selectedTeam,
      showMaintenance: props.showMaintenance,
      selectedFloor: props.selectedFloor
    };

    this.onFullscreenClick = this.onFullscreenClick.bind(this);
    this.onWrenchClick = this.onWrenchClick.bind(this);
  }

  state: any = {
    filterListDepartment: [],
    filterListTeam: [],
    fullscreenTargetId: null,
    floorList: [],
    selectedDepartment: 'ALL',
    selectedTeam: 'ALL',
    showMaintenance: false,
    selectedFloor: 0
  };

  UNSAFE_componentWillReceiveProps(props: Readonly<MenuProps>) {
    this.setState({
      filterListDepartment: props.filterListDepartment,
      filterListTeam: props.filterListTeam,
      floorList: props.floorList,
      fullscreenTargetId: props.fullscreenTargetId,
      showMaintenance: props.showMaintenance,
      selectedFloor: props.selectedFloor
    });
  }

  render() {
    return (
      <div className="fl-menu-main">
        <i className="fa fa-cog fa-3x" aria-hidden="true"></i>
        <div className="fl-dropdown-content">
          <i className="fa fa-filter fa-2x" aria-hidden="true"></i>
          <span className="fl-menu-mmms-toggle" onClick={this.onWrenchClick}>
            {this.renderWrenchState()}
            <i className="fa fa-wrench fa-2x" aria-hidden="true"></i>
          </span>
          <i className="fa fa-arrows-alt fa-2x" aria-hidden="true" onClick={this.onFullscreenClick}></i>
          {this.createListItems()}
        </div>
      </div>
    );
  }

  onWrenchClick() {
    const toggle = !this.state.showMaintenance;
    this.setState({ showMaintenance: toggle });
    this.props.onToggleShowMaintenance(toggle);
  }

  onFullscreenClick() {
    const element = document.getElementById(this.state.fullscreenTargetId);
    if (!element) {
      return;
    }
    if (screenfull.isEnabled) {
      screenfull.toggle(element).then(() => {
        console.log('change happened!');
      });
    }
  }

  onDepartmentClick(event: any) {
    this.setState({ selectedDepartment: event.currentTarget.dataset.id });
    this.props.onSelectDepartmentFilter(event.currentTarget.dataset.id);
  }
  onTeamClick(event: any) {
    this.setState({ selectedTeam: event.currentTarget.dataset.id });
    this.props.onSelectTeamFilter(event.currentTarget.dataset.id);
  }
  onFloorClick(event: any) {
    this.setState({ selectedFloor: parseInt(event.currentTarget.dataset.id, 10) });
    this.props.onSelectFloor(parseInt(event.currentTarget.dataset.id, 10));
  }

  renderWrenchState(): React.ReactNode {
    if (this.state.showMaintenance) {
      return <i className="fa fa-check fa-2x" aria-hidden="true"></i>;
    }
    else { return <i className="fa fa-times fa-2x" aria-hidden="true"></i>; }
  }

  createListItems(): React.ReactNode[] {
    const children: React.ReactNode[] = [];
    if (this.state.filterListDepartment) {
      children.push(<div className="fl-filter-title">{this.props.options.departmentTxt}</div>);
      children.push(
        <a
          href="#"
          onClick={e => this.onDepartmentClick(e)}
          data-id="ALL"
          className={this.state.selectedDepartment === 'ALL' ? 'fl-filter-on' : 'fl-filter-off'}
        >
          {this.props.options.allTxt}
        </a>
      );
      this.state.filterListDepartment.forEach((g: string[]) => {
        children.push(
          <a
            href="#"
            onClick={e => this.onDepartmentClick(e)}
            data-id={g}
            className={this.state.selectedDepartment === g ? 'fl-filter-on' : 'fl-filter-off'}
          >
            {g}
          </a>
        );
      });
    }
    if (this.state.filterListTeam) {
      children.push(<div className="fl-filter-title">{this.props.options.teamTxt}</div>);
      children.push(
        <a href="#" onClick={e => this.onTeamClick(e)} data-id="ALL" className={this.state.selectedTeam === 'ALL' ? 'fl-filter-on' : 'fl-filter-off'}>
          {this.props.options.allTxt}
        </a>
      );
      this.state.filterListTeam.forEach((g: string[]) => {
        children.push(
          <a href="#" onClick={e => this.onTeamClick(e)} data-id={g} className={this.state.selectedTeam === g ? 'fl-filter-on' : 'fl-filter-off'}>
            {g}
          </a>
        );
      });
    }

    if (this.state.floorList) {
      children.push(<div className="fl-filter-title">{this.props.options.floorTxt}</div>);
      this.state.floorList.forEach((g: any) => {
        children.push(
          <a href="#" onClick={e => this.onFloorClick(e)} data-id={g.floor} className={this.state.selectedFloor === g.floor ? 'fl-filter-on' : 'fl-filter-off'}>
            {g.name}
          </a>
        );
      });
    }
    return children;
  }
}
export default Menu;
