import React, { Component } from 'react';
import { MetricPanelProps, LayoutMachine } from 'types';
import Metric from 'Metric';

class MetricPanel extends Component<MetricPanelProps> {
    constructor(props: Readonly<MetricPanelProps>) {
        super(props);
    }

    state: any = {
        show: false,
    };

    UNSAFE_componentWillReceiveProps(props: Readonly<MetricPanelProps>) {
        this.setState({ show: props.show, machine: props.machine });
    }

    render() {
        let className = 'fl-metric-panel'; 
        if (this.state.machine) {
            className += this.state.machine.userData.metric.length <= 6 ? ' fl-metric-panel-single' : ' fl-metric-panel-double'
        }
        return (
            <div className={className}>
                {this.createMetric()}
            </div>
        );
    }

    createMetric(): React.ReactNode[] {
        const children: React.ReactNode[] = [];
        if (!this.state.machine) {
            return [];
        }
        const metricValue = (this.state.machine.userData as LayoutMachine).metric;
        metricValue.forEach(m => {
            children.push(<Metric metric={m} show={this.state.show} options={this.props.options} />);
        });

        return children;
    }
}
export default MetricPanel;
